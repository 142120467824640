//stats articlesasdas
var consumprionFlag;

(function () {
  var scale = 4; // dokładność w 1/scale  dla 4 będzie 25% czyli ćwiartki
  var sTS = -1,
    T = [],
    checkPosition = function () {
      var hh = document.querySelector('header.header').scrollHeight, //header height
        vh = document.documentElement.clientHeight; //viewport height

      document.querySelectorAll('.__js_stats_wrapper').forEach(function (e, n) {
        var tt = parseInt(e.getBoundingClientRect().top),
          bt = parseInt(e.getBoundingClientRect().bottom),
          g = parseInt(vh - tt - hh), //góra artykułu w viewport
          d = parseInt(bt - hh), //dół artykułu w viewport
          r = parseInt((e.scrollHeight + hh) / scale), //ratio
          statId = e.getAttribute('data-id');

        //Jeżeli nie ma jeszcze tablicy flag to ją utwórz
        if (typeof T[n] == 'undefined') {
          T[n] = [];
          //inicjacja tablic flag dla kolejnych ćwiartek
          for (var k = 1; k <= scale; k++) {
            T[n][k] = {};
            T[n][k].begin = 0;
            T[n][k].end = 0;
            T[n][k].send = 0;
          }
        }

        //czy artykuł jest w vieportcie
        if (g > 0 && d > 0) {
          if (typeof T[n][0] == 'undefined') {
            T[n][0] = 1;
            //PageViews

            // sendStats('view', 'scroll', statId);
          }
        }

        for (var k = 1; k <= scale; k++) {
          if (
            !T[n][k].begin &&
            tt < -(k - 1) * r + vh + hh &&
            tt > -(k - 1) * r
          )
            T[n][k].begin = 1;
          if (!T[n][k].end && tt < -k * r + vh + hh && tt > -k * r)
            T[n][k].end = 1;
        }

        for (var k = 1; k <= scale; k++) {
          if (!T[n][k].send && T[n][k].begin && T[n][k].end) {
            // staty dla ćwiartek
            // sendStats('progress', 'scroll', statId, k * (100 / scale));

            T[n][k].send = 1;
            indT.push(n);
          }
        }

        if (typeof window.debug) {
          $('#statArticle-' + n + '-0').html(T[n][0]);
          for (var k = 1; k <= scale; k++) {
            $('#statArticle-' + n + '-' + k + 'b').html(T[n][k].begin);
            $('#statArticle-' + n + '-' + k + 'e').html(T[n][k].end);
            if (T[n][k].send)
              $(
                '#statArticle-' +
                  n +
                  '-' +
                  k +
                  'b, #statArticle-' +
                  n +
                  '-' +
                  k +
                  'e'
              ).css({ backgroundColor: '#bbffc3' });
            $('#statArticle-' + n + '-g').html(tt);
            $('#statArticle-' + n + '-d').html(bt + ' ratio: ' + r);
          }
        }
      });
    };

  window.addEventListener('load', function () {
    if (sTS > 0) clearTimeout(sTS);
    sTS = setTimeout(checkPosition, 750);
  });

  window.addEventListener('scroll', function () {
    if (sTS > 0) clearTimeout(sTS);
    sTS = setTimeout(checkPosition, 50);
  });

  //debugger
  //    $(function(){
  //    var $debug = $('<div>').css({position:"fixed",bottom:20,right:20,width:100,border:'1px solid #555',padding:2,font:"10px/16px Arial",zIndex:1e4,width:"auto",backgroundColor:"#fafafa"}).html('debug');
  //
  //        $('.__js_stats_wrapper').each(function(n){
  //            var $c = $('<div id="statArticle-'+n+'">').html($(this).data('id')+" ");
  //            $('<span id="statArticle-'+n+'-0">').html("o").appendTo($c);
  //            $('<span>').html(", ").appendTo($c);
  //            $('<span id="statArticle-'+n+'-1b">').html("b").appendTo($c);
  //            $('<span id="statArticle-'+n+'-1e">').html("e").appendTo($c);
  //            $('<span>').html(", ").appendTo($c);
  //            $('<span id="statArticle-'+n+'-2b">').html("b").appendTo($c);
  //            $('<span id="statArticle-'+n+'-2e">').html("e").appendTo($c);
  //            $('<span>').html(", ").appendTo($c);
  //            $('<span id="statArticle-'+n+'-3b">').html("b").appendTo($c);
  //            $('<span id="statArticle-'+n+'-3e">').html("e").appendTo($c);
  //            $('<span>').html(", ").appendTo($c);
  //            $('<span id="statArticle-'+n+'-4b">').html("b").appendTo($c);
  //            $('<span id="statArticle-'+n+'-4e">').html("e").appendTo($c);
  //            $('<span>').html(" | ").appendTo($c);
  //            $('<span id="statArticle-'+n+'-g">').html("g").appendTo($c);
  //            $('<span>').html(", ").appendTo($c);
  //            $('<span id="statArticle-'+n+'-d">').html("d").appendTo($c);
  //            $c.appendTo($debug);
  //        });
  //        $debug.appendTo(document.body);
  //        window.debug = 1;
  //
  //
  //    });
})();
