const setSize = function () {
  document.documentElement.style.setProperty(
    '--browserArea',
    window.innerHeight - document.querySelector('.header').offsetHeight + 'px'
  );
  let divideNum;
  if (window.innerWidth > 1100) {
    divideNum = 2;
  }
  if (window.innerWidth < 1101) {
    divideNum = 1;
  }
  if (window.innerWidth > 768) {
    const multiNum = Math.ceil(
      (document.querySelectorAll('.header--nav ul li').length - 1) / divideNum
    );
    document.documentElement.style.setProperty(
      '--navBgSize',
      `${
        multiNum *
          document.querySelector('.header').getBoundingClientRect().height
      }px`
    );
  }
  if (window.innerWidth < 769 && window.innerWidth > 640) {
    document.documentElement.style.setProperty(
      '--navBgSize',
      `${
        document.querySelector('.header').getBoundingClientRect().height *
        (document.querySelectorAll('.header--nav ul li').length + 1) + 32
      }px`
    );
  }
  if (window.innerWidth <= 640) {
    document.documentElement.style.setProperty(
      '--navBgSize',
      `${
        (document.querySelector('.header').getBoundingClientRect().height + 10) *
        (document.querySelectorAll('.header--nav ul li').length) + 32 +
        document.querySelector('.header').getBoundingClientRect().height
      }px`
    );
  }
};

window.addEventListener('DOMContentLoaded', () => {
  setSize();
});

window.addEventListener('resize', (event) => {
  setSize();
});

window.addEventListener('orientationchange', (event) => {
  setSize();
});

// document.querySelectorAll('.sticky__images').forEach((el) => {
//   if (el.querySelectorAll('.photo__full-height').length > 0) {
//     el.classList.add('sticky--full');
//     el.nextElementSibling.classList.add('bg-a--full');
//   }
//   if (el.querySelectorAll('.photo__medium-height').length > 0) {
//     el.classList.add('sticky--medium');
//     el.nextElementSibling.classList.add('bg-a--medium');
//   }
// });
