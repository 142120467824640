const cutWords = function (selector, length) {
  if (window.innerWidth > 768) {
    if (document.querySelectorAll(selector).length > 0) {
      document.querySelectorAll(selector).forEach((el) => {
        if (el.innerText.length > length) {
          el.innerText = el.innerText.substr(0, length) + '\u2026';
        }
      });
    }
  }
};

// cutWords('.header--nav ul li h2', 48);
