'use strict';

var hotpointSliders = $('.hotpoints--slider').each(function () {
  var $artID = $(this).parents('.__js_stats_wrapper').data('id');
  //sprawdzamy, czy jest ustawiony id dla statystyk
  var ga360id = $(this).data('ga360-id');
  if (ga360id == undefined) {
    console.log(
        '%c dodać ga360-id do Slider hotpoint: ' + $(this).data('id'),
        'background: #553311; padding: 3px; color: #ffaaaa'
    );
  } else {
    statElementCollector[ga360id + '-' + $artID] = {
      id: ga360id,
      moduleName: $(this).data('id'),
      viewedCount: 0,
      itemCount: $(this).find('.hotpoints--range').length,
    };
  }
  $(this).statVievedSlide = [];
  //pozycje

  slidePositionVieved[ga360id + '-' + $artID] = {};
  this.insertAdjacentHTML('beforeend', '<div class="hotpoints__desc"></div>');
  $(this)
      .find('.hotpoints__desc')
      .html(
          $(this).find('.hotpoints--range').eq(0).find('.hotpoints--desc').html()
      );

  const slider = this;

  window.addEventListener('load', () => {
    slider.querySelectorAll('.hotpoints--range').forEach(function (el, idx) {
      const sw = slider.offsetWidth;
      const sh = slider.offsetHeight;
      const sx = slider.getBoundingClientRect().x;
      const sy = slider.getBoundingClientRect().y;
      const ex = el.getBoundingClientRect().x;
      const ey = el.getBoundingClientRect().y;
      if ((ex - sx) / sw < 0.35) {
        el.querySelector('.hotpoints--desc').classList.add('desc--right');
      } else if ((ex - sx) / sw > 0.65) {
        el.querySelector('.hotpoints--desc').classList.add('desc--left');
      } else {
        if ((ey - sy) / sh < 0.5) {
          el.querySelector('.hotpoints--desc').classList.add('desc--bottom');
        } else {
          el.querySelector('.hotpoints--desc').classList.add('desc--top');
        }
      }
    });
  });
});

$('.hotpoints--slider').on('mouseenter', '._js_switch', function () {
  var current = 0,
      $slider = $(this).parents('.hotpoints--slider');

  current = $(this).parent().index();

  $slider
      .find('.hotpoints__desc')
      .html(
          $slider
              .find('.hotpoints--range')
              .eq(current)
              .find('.hotpoints--desc')
              .html()
      );

  $slider
      .find('.hotpoints--range')
      .removeClass('isActive')
      .eq(current)
      .addClass('isActive');
  $slider.find('.hotpoints--slider-bg').addClass('img--hovered');

  if (isScrolling) return;

  var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
      $galID = $(this).parents('.hotpoints--slider').data('id'),
      $acId = $(this).parent().find('.hotpoints--point').data('point'),
      ga360id = $(this).parents('.hotpoints--slider').data('ga360-id');

  if (!ga360id) {
    console.log(
        '%c dodać ga360-id do hotpointow!' +
        $galID +
        'background: #3b3b3b; padding: 3px; color: #beeb2f'
    );
  }
  // console.log('art:' + $artID + 'gal:' + $galID + ' / img: ' + $imgID);

  slidePositionVieved[ga360id + '-' + $artID][current] = 1;
  statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(
      slidePositionVieved[ga360id + '-' + $artID]
  ).length;

  sendStats('hotpoint', 'click', $artID + ' / ' + $galID, $acId);

  if (!isScrolligFlag) {
    WP.push(function () {
      WP.pwp.customAction({
        ga: {
          event: 'content_interaction',
          eventCategory: ga360id,
          moduleName: $galID,
          eventAction: deviceTouch ? 'tap' : 'hover',
        },
      });
    });
  }

  $slider.find('._js_switch').bind('mouseleave', function () {
    current = $(this).parent().index();
    $slider.find('.hotpoints--range').removeClass('isActive');
    $slider.find('.hotpoints--slider-bg').removeClass('img--hovered');
  });
});
