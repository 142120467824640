'use strict';

const map = $('#map svg');
const regions = map.find('.region');
const descriptions = $('#map .map-description_wrapper');

//przy obsłudze svg path nie działa addClass i removeClass

const linkedRegions = {
    'ZP': 'LS', // Zachodniopomorskie i Lubuskie
    'LS': 'ZP', // Lubuskie i Zachodniopomorskie
    'DS': 'OP', // Dolnośląskie i Opolskie
    'OP': 'DS', // Opolskie i Dolnośląskie
    'WM': 'PL', // Warmińsko-Mazurskie i Podlaskie
    'PL': 'WM', // Podlaskie i Warmińsko-Mazurskie
    'MP': 'SK', // Małopolskie i Świętokrzyskie
    'SK': 'MP' // Świętokrzyskie i Małopolskie
};

function showDescription(regionName) {
    const regionData = '.region_' + regionName;

    descriptions.find('.region_wrapper').removeClass('_active');
    descriptions.find('.region_wrapper .region-desc').removeClass('_active');

    descriptions.find(regionData).addClass('_active');

    descriptions.find('.region_wrapper._active .region-desc').removeClass('_active');
    descriptions.find('.region_wrapper._active .region-desc_1').addClass('_active');
}

function selectRegion(region) {
    const regionName = region.attr('data-region');
    const linkedRegionName = linkedRegions[regionName];

    regions.each(function() {
        $(this).attr('class', 'region');
    });

    region.attr('class', 'region _active');
    showDescription(regionName);

    if (linkedRegionName) {
        const linkedRegion = regions.filter(`[data-region="${linkedRegionName}"]`);
        linkedRegion.attr('class', 'region _active');
        showDescription(linkedRegionName);
    }
}

regions.each(function() {
    $(this).on('click', function(e) {
        if (!this.classList.contains('_active')) {
            selectRegion($(this));
        }
        else {
            regions.each(function() {
                $(this).attr('class', 'region');
            });
            descriptions.find('.region_wrapper._active .region-desc').removeClass('_active');
            descriptions.find('.region_wrapper').removeClass('_active');
        }
    });
});

$('.region-desc_button').each(function() {
    $(this).on('click', function() {
        $(this).parent('.region-desc').addClass('choosed');
        $(this).parents('.region_wrapper').find('.region-desc:not(.choosed)').removeClass('_active');
        $(this).parent('.region-desc').toggleClass('_active');
        $(this).parent('.region-desc').removeClass('choosed');
    });
});
