'use strict';

(function () {
  var MenuToggler = $('.header--hamburger').click(function (e) {
    $(this).toggleClass('__js_isActive');
    $(this).parent().find('nav').toggleClass('__js_isActive');
    $('.header').toggleClass('header--active');
  });
  $('.header--nav ul').on('click', function () {
    $('.header--nav').removeClass('__js_isActive');
    $('.header--hamburger').removeClass('__js_isActive');
    $('.header').removeClass('header--active');
  });
  $('.header').on('mouseleave', function () {
    $(this).find('.header--hamburger').removeClass('__js_isActive');
    $(this).parents().find('.header--nav').removeClass('__js_isActive');
    $('.header').removeClass('header--active');
  });
})();

$('.header--hamburger').append('<span></span><span></span><span></span>');
