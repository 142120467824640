//share map, facebook, twitter, ga360
(function () {
  const regionMap = {
    'LS': 'click_Lubuskie',
    'ZP': 'click_Zachodniopomorskie',
    'DS': 'click_Dolnośląskie',
    'OP': 'click_Opolskie',
    'WP': 'click_Wielkopolskie',
    'PM': 'click_Pomorskie',
    'KP': 'click_Kujawsko-Pomorskie',
    'SL': 'click_Śląskie',
    'LD': 'click_Łódzkie',
    'MP': 'click_Małopolskie',
    'SK': 'click_Świętokrzyskie',
    'WM': 'click_Warmińsko-Mazurskie',
    'PK': 'click_Podkarpackie',
    'MZ': 'click_Mazowieckie',
    'PL': 'click_Podlaskie',
    'LB': 'click_Lubelskie'
  };

  const fixedOrderPairs = {
    'PL': 'WM', // Podlaskie i Warmińsko-Mazurskie
    'MP': 'SK', // Małopolskie i Świętokrzyskie
    'DS': 'OP', // Dolnośląskie i Opolskie
    'LS': 'ZP'  // Lubuskie i Zachodniopomorskie
  };

  const pairNames = {
    'PL': 'click_Podlaskie i Warmińsko-Mazurskie',
    'WM': 'click_Podlaskie i Warmińsko-Mazurskie',
    'MP': 'click_Małopolskie i Świętokrzyskie',
    'SK': 'click_Małopolskie i Świętokrzyskie',
    'DS': 'click_Dolnośląskie i Opolskie',
    'OP': 'click_Dolnośląskie i Opolskie',
    'LS': 'click_Lubuskie i Zachodniopomorskie',
    'ZP': 'click_Lubuskie i Zachodniopomorskie'
  };

  $('.__js_socialShare').click(function (e) {
    e.preventDefault();

    var categoryName = $(this).data('category'),
        href = $(this).data('href'),
        $aID = $(this).parents('.__js_stats_wrapper').data('id'),
        regionName = $(this).data('region'),
        ga360Socials;

    if ($aID == null) {
      $aID = 'footer';
    }

    sendStats('share-' + categoryName, 'click', $aID);

    // do 360 mają być inne nazwy
    if (categoryName === 'facebook') {
      window.open(
          href,
          '_blank',
          'top=100, left=320, width=320,height=280,toolbar=1,resizable=0'
      );
      ga360Socials = 'share_FB';
    }
    if (categoryName === 'twitter') {
      window.open(
          href,
          '_blank',
          'top=100, left=400, width=602,height=400,toolbar=1,resizable=0'
      );
      ga360Socials = 'share_Twitter';
    }

    if (regionMap.hasOwnProperty(regionName)) {
      if (pairNames.hasOwnProperty(regionName)) {
        ga360Socials = pairNames[regionName];
      } else {
        ga360Socials = regionMap[regionName];
      }
    }

    WP.push(function () {
      WP.pwp.customAction({
        ga: {
          event: 'site_interaction',
          eventCategory: ga360Socials,
          eventAction: 'click',
        },
      });
    });
  });
})();
