var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var playerInfoList = [];

$('.player-yt').each(function () {
  playerInfoList.push({
    id: $(this).attr('id'),
    videoId: $(this).attr('data-video-id'),
  });
});

function onYouTubeIframeAPIReady() {
  if (typeof playerInfoList === 'undefined') return;

  for (var i = 0; i < playerInfoList.length; i++) {
    var curplayer = createPlayer(playerInfoList[i]);
    players[i] = curplayer;
  }
}

var players = new Array();

function createPlayer(playerInfo) {
  return new YT.Player(playerInfo.id, {
    videoId: playerInfo.videoId,
    playerVars: { autoplay: 0 },
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
    },
  });
}

function onPlayerReady(event) {
  // event.target.playVideo();
  // event.target.stopVideo();
}

function onPlayerStateChange(event) {}

//wp player
window.addEventListener('DOMContentLoaded', () => {
  $('.playerA').each(function (i, e) {
    $(this).attr('id', 'player_' + i);
    var url = $(this).attr('data-url');
  
    WP.push(function () {
      var playerInitialized = false;
      var statsQuadrantsPos = {};
      var clipName;
      var clipMid;
      var player = new WP.player({
        url: url,
        target: 'player_' + i,
        adv: false,
        hiderelated: true,
        extendedrelated: false,
        floatingplayer: false,
        autoplay: true,
        forceliteembed: false,
      });
      player.on('INIT', function () {
        if (!playerInitialized) {
          playerInitialized = true;
          player.setVolume(0.5);
        }
      });
      player.on('START_MOVIE', function () {
        clipName = player.getClipData().title;
        clipMid = player.getClipData().mid;
        $('.section--head--button').hide();
        sendStats('video', 'view', clipName);
        // console.log("video", "view", clipName);
      });
      player.on('END_MOVIE', function () {
        $('.section--head--button').show();
        sendStats('video', 'end', clipName);
        // console.log("video", "end", clipName);
      });
      player.on('UPDATE_POSITION', function (e) {
        var position = player.getPosition();
        statsQuadrants(position);
      });
      var statsQuadrants = function (p) {
        p = Math.round(p * 100);
        if (!statsQuadrantsPos[clipMid]) {
          statsQuadrantsPos[clipMid] = {};
        }
        if (p >= 25 && p < 50) {
          if (!statsQuadrantsPos[clipMid][25]) {
            statsQuadrantsPos[clipMid][25] = 1;
            sendStats('video', 'quarter=25', clipName);
            // console.log("video", "quarter=25", clipName);
          }
        } else if (p >= 50 && p < 75) {
          if (!statsQuadrantsPos[clipMid][50]) {
            statsQuadrantsPos[clipMid][50] = 1;
            sendStats('video', 'quarter=50', clipName);
            // console.log("video", "quarter=50", clipName);
          }
        } else if (p >= 75 && p < 100) {
          if (!statsQuadrantsPos[clipMid][75]) {
            statsQuadrantsPos[clipMid][75] = 1;
            sendStats('video', 'quarter=75', clipName);
            // console.log("video", "quarter=75", clipName);
          }
        }
      };
    });
  });
  
});
